import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NavBar from './NavBar';
import MobileNavBar from './MobileNavBar';
import Home from './Home';
import { AboutUs } from './AboutUs';
import Menu from './Menu';
import ContactUs from './ContactUs';
import { NotFound } from './NotFound';
import '../styles/App.scss';
import CommentViewer from './CommentViewer';
import ApplicationViewer from './ApplicationViewer';
import Employment from './Employment';
import Specials from './Specials';
import MobileCatering from './MobileCatering';
import ScrollToTop from './ScrollToTop';

function App() {
  const [showMobile, setShowMobile] = useState(window.innerWidth > 700 ? false : true);
  const [language, setLanguage] = useState(localStorage.getItem('triniandcarmensLanguage') == 'true' ? true : localStorage.getItem('triniandcarmensLanguage') == null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 850) {
        setShowMobile(false);
      } else {
        setShowMobile(true);
      }
    };
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <BrowserRouter basename='/'>
      <ScrollToTop/>
      <div className="app">
        {!showMobile && <NavBar setLanguage={setLanguage} language={language} />}
        {showMobile && <MobileNavBar setLanguage={setLanguage} language={language} />}
        <div className="body">
            <Routes>
              <Route path="/" element={<Home showMobile={showMobile} language={language} />} />
              {!showMobile && <Route path="specials" element={<Specials language={language} />} />}
              {showMobile && <Route path="specials" element={<Navigate to="/Menu/Menu.pdf"/>} />}
              <Route path="menu" element={<Menu isMobile={showMobile}/>} />
              <Route path="contact-us" element={<ContactUs language={language} />} />
              <Route path="about-us" element={<AboutUs language={language} />} />
              <Route path="employment" element={<Employment language={language} />} />
              <Route path="view-comments" element={<CommentViewer showMobile={showMobile} language={language} />} />
              <Route path="view-applications" element={<ApplicationViewer language={language} />} />
              <Route path="catering" element={<MobileCatering language={language} />} />
              <Route path="*" element={<NotFound language={language} />} language={language} />
            </Routes>
        </div>
        <div className="footer">© 2024 by Trini and Carmen’s.</div>
      </div>
    </BrowserRouter>
  );
}

export default App;
