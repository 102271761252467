import React from "react";
import DisplayPDF from "./DisplayPDF";
import '../styles/Menu.scss';

const url = process.env.PUBLIC_URL + "/Menu/Menu.pdf";

function Menu(props) {
    return (
        <div className={props.isMobile ? "menu-pdf" : "menu-pdf desktop"}>
            <DisplayPDF pdf={url} />
        </div>
    );
}

export default Menu;