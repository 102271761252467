import React from "react";
import '../styles/NavBar.scss';
import { HamburgerButton } from "./HamburgerButton";
import { Link } from "react-router-dom";
import americanFlag from "../favicons/american-flag.ico";
import mexicanFlag from "../favicons/mexican-flag.ico";

const NavBar =(props)=> {
    const handleLanguageChange = event => {
        props.setLanguage(!props.language);
    }

    return <div className="nav-bar">
        <HamburgerButton language={props.language} />
        <Link to="/Menu/Menu.pdf" target="_blank" className="button">
            {props.language && "Menu"}
            {!props.language && "Menú"}
        </Link>
        <Link to="https://www.toasttab.com/trini-carmens-waterford/v3" className="button">
            {props.language && "Order Now"}
            {!props.language && "Pídalo Ya"}
        </Link>
        <Link onClick={handleLanguageChange} className="button">
            {props.language && <img src={americanFlag} height={24} alt="English"></img>}
            {!props.language && <img src={mexicanFlag} height={24} alt="Español"></img>}
        </Link>
    </div>
}

export default NavBar