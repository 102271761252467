import { PDFDocument } from 'pdf-lib';

async function fillForm(formData) {
    const EmploymentPdf = process.env.PUBLIC_URL + "/EmploymentApplicationFillable.pdf";
    const EmploymentPdfBytes = await fetch(EmploymentPdf).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(EmploymentPdfBytes);

    const form = pdfDoc.getForm();

    const position = form.getTextField('Position');
    position.setText(formData.position);

    const date = form.getTextField('Date');
    date.setText(formData.date);

    const jobreason = form.getTextField('JobReason');
    jobreason.setText(formData.jobreason);

    const firstname = form.getTextField('FirstName');
    firstname.setText(formData.firstname);

    const middlename = form.getTextField('MiddleName');
    middlename.setText(formData.middlename);

    const lastname = form.getTextField('LastName');
    lastname.setText(formData.lastname);

    const streetaddress = form.getTextField('StreetAddress');
    streetaddress.setText(formData.streetaddress);

    const citystatezip = form.getTextField('CityStateZip');
    citystatezip.setText(formData.citystatezip);

    const phone = form.getTextField('Phone');
    phone.setText(formData.phone);

    const transportation = form.getTextField('Transportation');
    transportation.setText(formData.reliablework);

    const transportationdescription = form.getTextField('TransportationDescription');
    transportationdescription.setText(formData.reliableworkdescription);

    const atleast18 = form.getTextField('AtLeast18');
    atleast18.setText(formData.legalemployment);

    const workpermit = form.getTextField('WorkPermit');
    workpermit.setText(formData.workpermit);

    const driverslicenseid = form.getTextField('DriversLicenseID');
    driverslicenseid.setText(formData.driverslicenseid);

    const driverslicensestate = form.getTextField('DriversLicenseState');
    driverslicensestate.setText(formData.driverslicensestate);

    const driverslicenseexpirationdate = form.getTextField('DriversLicenseExpirationDate');
    driverslicenseexpirationdate.setText(formData.driverslicenseexpirationdate);

    const legalemployment = form.getTextField('LegalEmployment');
    legalemployment.setText(formData.legalemployment);

    const crimeyescheckbox = form.getCheckBox('CrimeYesCheckbox');
    if (formData.crimecheckboxyes) {
        crimeyescheckbox.check();
    } else {
        crimeyescheckbox.uncheck();
    }

    const crimenocheckbox = form.getCheckBox('CrimeNoCheckbox');
    if (formData.crimecheckboxno) {
        crimenocheckbox.check();
    } else {
        crimenocheckbox.uncheck();
    }

    const crimedetail = form.getTextField('CrimeDetail');
    crimedetail.setText(formData.crimedetail);

    const veteranstatus = form.getTextField('VeteranStatus');
    veteranstatus.setText(formData.veteranstatus);

    const veteranstart = form.getTextField('VeteranStart');
    veteranstart.setText(formData.veteranstart);

    const veteranend = form.getTextField('VeteranEnd');
    veteranend.setText(formData.veteranend);

    const veterantraining = form.getTextField('VeteranTraining');
    veterantraining.setText(formData.veterantraining);

    const employmenttype = form.getTextField('EmploymentType');
    employmenttype.setText(formData.employmenttype);

    const employmenthours = form.getTextField('EmploymentHours');
    employmenthours.setText(formData.employmenthours);

    const employmenthoursno = form.getTextField('EmploymentHoursNo');
    employmenthoursno.setText(formData.employmenthoursno);

    const overtime = form.getTextField('Overtime');
    overtime.setText(formData.overtime);

    const weekends = form.getTextField('Weekends');
    weekends.setText(formData.weekends);

    const holidays = form.getTextField('Holidays');
    holidays.setText(formData.holidays);

    const currentlyemployed = form.getTextField('CurrentlyEmployed');
    currentlyemployed.setText(formData.currentlyemployed);

    const hiredstart = form.getTextField('HiredStart');
    hiredstart.setText(formData.hiredstart);

    const workedherebefore = form.getTextField('WorkedHereBefore');
    workedherebefore.setText(formData.workedherebefore);

    const workedherebeforename = form.getTextField('WorkedHereBeforeName');
    workedherebeforename.setText(formData.workedherebeforename);

    const employmentfriends = form.getTextField('EmploymentFriends');
    employmentfriends.setText(formData.employmentfriends);

    const priordischarge = form.getTextField('PriorDischarge');
    priordischarge.setText(formData.priordischarge);

    const priordischargedescription = form.getTextField('PriorDischargeDescription');
    priordischargedescription.setText(formData.priordischargedescription);

    const abletoperform = form.getTextField('AbleToPerform');
    abletoperform.setText(formData.abletoperform);

    const accommodationdetail = form.getTextField('AccommodationDetail');
    accommodationdetail.setText(formData.accommodationdetail);

    const accommodationdescription = form.getTextField('AccommodationDescription');
    accommodationdescription.setText(formData.accommodationdescription);

    const lastemployer = form.getTextField('LastEmployer');
    lastemployer.setText(formData.lastemployer);

    const lastemployerphone = form.getTextField('EmployerPhone');
    lastemployerphone.setText(formData.lastemployerphone);

    const pdfBytes = await pdfDoc.save();

    return(pdfBytes);
}

export default fillForm;