import React from "react";
import "../styles/Specials.scss";
import DisplayPDF from "./DisplayPDF";
import { Link } from "react-router-dom";

const PartyTrayUrl = process.env.PUBLIC_URL + "/PartyTrayMenu.pdf";
const BuffetFormUrl = process.env.PUBLIC_URL + "/BuffetOrderForm.pdf";

function Specials(props) {
    return <>
        <header className="specials-header">
            {props.language && "Specials"}
            {!props.language && "Especiales"}
        </header>
        <div className="specials">
            <div className="horizontal-list">
                <div className="post post-wide border-blue">
                    <div className="header header-blue">
                        {props.language && "Daily Specials"}
                        {!props.language && "Platos del Día"}
                    </div>
                    <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={320} height={25} className="break"/>
                    <div className="text">
                        {props.language && 
                        <>
                            Monday – $2.00 off Nacho Supreme
                        </>}
                        {!props.language &&
                        <>
                            Lunes - $2.00 de descuento Nacho Supreme
                        </>}
                        <br/>
                    </div>
                    <br/>
                    <div className="text">
                        {props.language && 
                        <>
                            Tuesday – Taco Tuesday<br/>
                            6-Pack Hard shell:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$10.99<br/>
                            10-Pack Hard Shell:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$15.99<br/>
                            *Chicken &amp; soft shell add $1.00
                        </>}
                        {!props.language && 
                        <>
                            Martes - Taco Tuesday<br/>
                            Paquete de seis de cascara dura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$10.99<br/>
                            Paquete de diez de cascara dura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$15.99<br/>
                            *Pollo &amp; cascara suave agregar $1.00
                        </>}
                    </div>
                </div>
                <div className="vertical-block"/>
                <div className="post post-widest border-green">
                    <div className="header header-green">
                        {props.language && "Monthly Drink Specials"}
                        {!props.language && "Bebidas Especiales Mensuales"}
                    </div>
                    <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={530} height={30} className="break"/>
                    <div className="text">
                        {props.language && 
                        <>
                            Margarita of the Month:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$6<br/>
                            Carmen's Blue<br/>
                            <br/>
                            1800 Coconut Marg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$11<br/>
                            <br/>
                            House Sangria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$7<br/>
                            Blend of white or red wine, brandy, hard seltzer, citrus juices, topped with cinnamon<br/>
                            <br/>
                            Mexican Beer:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3.50<br/>
                            Estrella 
                            Corona
                            Modelo 
                        </>}
                        {!props.language && 
                        <>
                            Margarita del Mes:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$6<br/>
                            Carmen's Blue<br/>
                            <br/>
                            1800 Coconut Marg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$11<br/>
                            <br/>
                            House Sangria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$7<br/>
                            Mezcla de vino blanco o tinto, brandy, seltzer duro, zumos de cítricos, cubierto con canela<br/>
                            <br/>
                            Cerveza Mexicana:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3.50<br/>
                            Estrella 
                            Corona
                            Modelo 
                        </>}
                    </div>
                </div>
                <div className="vertical-block"/>
                <div className="post post-wide border-red">
                    <div className="header header-red">
                        {props.language && "Discounts"}
                        {!props.language && "Descuentos"}
                    </div>
                    <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={320} height={25} className="break"/>
                    <div className="text">
                        {props.language && 
                        <>
                            Veteran and Military:<br/>10% off<br/>
                            <br/>
                            Oakland County employees:<br/>10% off
                        </>}
                        {!props.language && 
                        <>
                            Veteranos y militares:<br/>10% de descuento<br/>
                            <br/>
                            Empleados del condado de Oakland:<br/>10% de descuento
                        </>}
                    </div>
                </div>
            </div>
            <div className="specials-spacer"/>
            <div className="specials-banner">
                {props.language &&
                <>
                    For party trays, buffets, and room booking, please consult the PDFs below:
                </>}
                {!props.language &&
                <>
                    Para bandejas de fiesta, buffets y reserva de habitaciones, consulte los PDF a continuación:
                </>}
            </div>
            <div className="horizontal-list gray-background">
                <div>
                    <Link to="/PartyTrayMenu.pdf" target="_blank" download className="specials-pdf-link">
                        {props.language && 
                        <>
                            Party Tray PDF download
                        </>}
                        {!props.language &&
                        <>
                            Descarga de PDF de Party Tray
                        </>}
                    </Link>
                    <br/><br/>
                    <DisplayPDF pdf={PartyTrayUrl}/>
                </div>
                <div className="vertical-block"/>
                <div>
                    <Link to="/BuffetOrderForm.pdf" target="_blank" download className="specials-pdf-link">
                        {props.language &&
                        <>
                            Buffet Order Form PDF download
                        </>}
                        {!props.language &&
                        <>
                            Formulario de pedido buffet PDF descarga
                        </>}
                    </Link>
                    <br/><br/>
                    <DisplayPDF pdf={BuffetFormUrl}/>
                </div>
            </div>
        </div>
    </>
}

export default Specials;