import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import "../styles/ImageCarousel.scss"

function ImageCarousel(props) {
    return (
        <Carousel 
            images={props.mapImages} 
            style={{ height: props.height, width: props.width }} 
            hasMediaButton={false}
            hasSizeButton={false}
            className='imageCarousel' />
    );
}

export default ImageCarousel
