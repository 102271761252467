import React from "react";
import "../styles/AboutUs.scss";

const AboutUs =(props)=> {
    return <>
        <header className="about-us-header">
            {props.language && "About Us"}
            {!props.language && "Sobre Nosotros"}
        </header>
        <div className="about-us-body">
        Our story begins with a young Mexican-American couple setting roots in the Pontiac area. After
        serving our country in the US Navy in WWII, Trinidad Martinez married Carmen Garcia in 1950,
        beginning a family. With a growing young family, Trini and Carmen each started a small business in
        Pontiac. Trini operated &quot;TJ&#39;s Bar&quot; on Saginaw Street and Carmen operated &quot;Carmen&#39;s Restaurant&quot; on
        Joslyn Road. With urban renewal in Pontiac and the expansion of General Motors in the 1960’s, Trini
        and Carmen combined the two small businesses; and on August 18, 1966, the original Trini &amp; Carmen&#39;s
        Restaurant opened its operation at 848 Woodward Avenue in Pontiac.
        </div>
        <br/>
        <div className="about-us-body">
        Trini &amp; Carmen’s restaurant quickly established itself as a popular restaurant in the Pontiac area,
        serving authentic tacos, burritos, enchiladas, and homemade tamales, made with the highest quality
        ingredients daily, along with its famous margaritas. With the creation “Trini’s” nacho in the early 1970’s,
        Trini &amp; Carmen’s soon become a landmark in the Metro-Detroit for having the best nachos in the area.
        Local and national newspapers and food magazine reviews have cemented Trini &amp; Carmen&#39;s as &quot;the
        place&quot; to find the best Mexican food in the Metro-Detroit area, especially our nachos and margaritas.
        The business continued to grow in popularity.
        </div>
        <br/>
        <div className="about-us-body">
        With the growing business, Trini and Carmen opened at second restaurant at our current location,
        1715 N. Telegraph Road, Trini &amp; Carmen’s Waterford, in September 1976. Both the Waterford
        restaurant and the original Woodward restaurant operated until in the Spring of 1989. In that year, St.
        Joseph&#39;s Hospital, the longtime neighbor of Trini and Carmen&#39;s Woodward restaurant, decided to
        expand and purchased the property leased by the restaurant, leaving our Trini &amp; Carmen&#39;s Waterford as
        the only remaining restaurant operating.
        </div>
        <br/>
        <div className="about-us-body">
        In May 1990, Trini, passed while in Mexico with Carmen. While this had been a difficult time for
        the Martinez family, Carmen remained at the helm of the restaurant’s operation with the help of several
        of her children and grandchildren. Trini &amp; Carmen’s Waterford business continued to grow and Carmen
        continued to be active in the community, receiving several awards for her commitment to it. Carmen
        passed in June 2018, surrounded by her and Trini’s twelve children, their spouses, grandchildren, and
        greatgrandchildren.
        </div>
        <br/>
        <div className="about-us-body">
        Over the years our menu has adapted to the culinary tastes of the times, adding various items to
        the menu. However, our signature nachos and margaritas have gained many loyal patrons throughout
        the years. Many other restaurants try to copy our nachos and margaritas, but all have fallen short. Our
        loyal customers come from far and wide. Patrons often tell us that we are their first stop when the get
        back to town as they cannot find any other nachos as good as ours anywhere.
        </div>
        <br/>
        <div className="about-us-body">
        The primary reason for our success is because we have always kept a firm root in the inherited
        tradition of Trini and Carmen to serve authentic Mexican cuisine made daily with the freshest and
        highest quality ingredients. While the original founders, Trini and Carmen, have passed, their legacy
        continues with the current operation of Trini &amp; Carmen’s Waterford by their children. We now carry on
        the values and high standards instilled in us by our parents and grandparents to a new generation of
        patrons in the 21st century, as well as our old ones. In the fifty-seventh (57) year of Trini &amp; Carmen’s
        operation, our traditional Mexican specialties are made daily, always starting with the freshest
        ingredients available and serving them to you, our loyal patrons, in a welcoming, family atmosphere.
        </div>
        <br/>
        <div className="about-us-body">
        Thank you all for your loyal support over the years. Come on in for our famous nachos and
        margaritas. We are pleased to serve you.
        </div>
        <div className="about-us-footer"/>
    </>
}

export { AboutUs }