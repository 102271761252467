import React from "react";
import "../styles/NotFound.scss";
import { Link } from "react-router-dom";

const NotFound =(props)=> {
    return <div className="not-found">
        404 Error
        <div className="not-found-text">
            {props.language && "The page you are looking for does not exist. You can go back, or try some of our links above."}
            {!props.language && "La página que está buscando no existe. Puede volver atrás, o probar algunos de nuestros enlaces de arriba."}
        </div>
        <div className="not-found-text">
            {props.language && <>If you got here via one of our links, please let us know through the <Link to="/contact-us" className="not-found-link">Contact Us</Link> page so we can fix it.</>}
            {!props.language && <>Si llegó aquí a través de uno de nuestros enlaces, por favor háganoslo saber a través de la página <Link to="/contact-us" className="not-found-link">Contáctenos</Link> para que podamos solucionarlo.</>}
        </div>
    </div>
}

export { NotFound }