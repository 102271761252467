import React, { useReducer, useState, useEffect } from "react";
import { validatePhone } from "./ContactUs";
// import ScheduleSelector from 'react-schedule-selector';
import Select from "react-dropdown-select";
import DisplayPDF from "./DisplayPDF";
import HideContentButton from "./HideContentButton";
import "../styles/Employment.scss";
import whiteIcon from "../favicons/white-trash-can.ico";
import fillForm from "./EmploymentForm";
import { Link } from "react-router-dom";
import axios from "axios";
import { Banner } from "./Banner";


const formReducer = (state, event) => {
    if(event.reset) {
        return {
            firstname: '',
            middlename: '',
            lastname: '',
            jobtype: '',
            email: '',
            phone: '',
            availability: '',
            resume: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

function CompareCheckboxValue(checkboxValue1, checkboxValue2) {
    const checkboxValue1True = checkboxValue1 === undefined ? false : checkboxValue1;
    const checkboxValue2True = checkboxValue2 === undefined ? false : checkboxValue2;
    return checkboxValue1True === checkboxValue2True;
}

function Employment(props) {
    const [formData, setFormData] = useReducer(formReducer, {});

    const [submitting, setSubmitting] = useState(false);

    // const [schedule, setSchedule] = useState([]);

    // const [showSchedule, setShowSchedule] = useState(false);

    const [listOfFiles, setListofFiles] = useState([]);

    const [generatedApplicationPdf, setGeneratedApplicationPdf] = useState(null);

    const [generatedApplicationBlob, setGeneratedApplicationBlob] = useState(null);

    const jobOptions = [
        {
            value: 1,
            label: "Cook"
        },
        {
            value: 2,
            label: "Dishwasher"
        },
        {
            value: 3,
            label: "Server"
        },
        {
            value: 4,
            label: "Busser"
        },
        {
            value: 5,
            label: "Host"
        }
    ];

    const [selectedJob, setSelectedJob] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        setFormData({
            name: 'date',
            value: currentDate.getMonth() + '/' + currentDate.getDate() + '/' + currentDate.getFullYear(),
        })
        const jobOptions = [
            {
                value: 1,
                label: "Cook"
            },
            {
                value: 2,
                label: "Dishwasher"
            },
            {
                value: 3,
                label: "Server"
            },
            {
                value: 4,
                label: "Busser"
            },
            {
                value: 5,
                label: "Host"
            }
        ];
        setFormData({
            name: 'position',
            value: jobOptions[selectedJob - 1]?.label,
        })
    }, [selectedJob])

    const handleSubmit = async (event) => {
        event.preventDefault();

        const currentDate = new Date();
        setFormData({
            name: 'date',
            value: currentDate.getMonth() + '/' + currentDate.getDate() + '/' + currentDate.getFullYear(),
        })
        
        if (selectedJob === 0) {
            alert("Please select a job to apply for");
            return;
        } else {
            setFormData({
                name: 'position',
                value: jobOptions[selectedJob - 1].label,
            })
        }

        // check if necessary inputs are filled out
        if (!formData.firstname || 
            !formData.lastname ||
            !formData.streetaddress ||
            !formData.citystatezip ||
            !formData.phone || 
            !formData.reliablework ||
            !formData.reliableworkdescription || 
            !formData.legalemployment || 
            !formData.crimedescription || 
            !formData.employmenttype || 
            !formData.employmenthours || 
            !formData.employmenthoursno || 
            !formData.currentlyemployed ||
            !formData.hiredstart ||
            !formData.lastemployer ||
            !formData.lastemployerphone
            ) {
            alert("One or more fields require entry");
            return;
        }

        // validate the phone number
        if (!validatePhone(formData.phone) || !validatePhone(formData.lastemployerphone)) {
            alert("Valid phone number required")
        }

        // check all checkboxes
        if (CompareCheckboxValue(formData.crimecheckboxyes, formData.crimecheckboxno) || 
            CompareCheckboxValue(formData.veterancheckboxyes, formData.veterancheckboxno) || 
            CompareCheckboxValue(formData.overtimecheckboxyes, formData.overtimecheckboxno) || 
            CompareCheckboxValue(formData.weekendscheckboxyes, formData.weekendscheckboxno) || 
            CompareCheckboxValue(formData.holidayscheckboxyes, formData.holidayscheckboxno)) {
            alert("Invalid input for a checkbox detected")
        }

        setFormData({
            name: 'veteranstatus',
            value: formData.veterancheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'overtime',
            value: formData.overtimecheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'weekends',
            value: formData.weekendscheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'holidays',
            value: formData.holidayscheckboxyes ? "Yes" : "No",
        });

        // may remove functionality as schedule is no longer in spec
        /*
        if (schedule.length === 0) {
            alert("Please fill available time slots");
            return;
        }
        */

        if (listOfFiles.length === 0) {
            alert("Please attach a resume");
            return;
        }

        // contact employmentForm API
        await fillForm(formData).then(
            (response) => {
                const blob = new Blob([response], {type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);

                setGeneratedApplicationPdf(blobUrl);
                setGeneratedApplicationBlob(blob);
            }
        );

        // send generated pdf and resume to backend to save
        const pdfForm = new FormData();
        for (var i = 0; i < listOfFiles.length; i++) {
            pdfForm.append(`file${i}`, listOfFiles[i]);
        }
        pdfForm.append('blobPdf', generatedApplicationBlob, `${formData.firstname + ' ' + formData.lastname + ' ' + (new Date(Date.now())).valueOf()}.pdf`);

        // setSubmitting(true);
        axios.post("/api/employee-application", pdfForm).then(res =>{
            if (res.status !== 200) {
                throw new Error(`Request failed: ${res.status}`);
            }
            // console.log(res);
        });
        
    }
    
    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        });

        // due to redundancy, I will invoke all checkbox options here each time. It may be a bit inefficient, but it gets rid of the need for an additional useEffect and detection for useReducer
        setFormData({
            name: 'veteranstatus',
            value: formData.veterancheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'overtime',
            value: formData.overtimecheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'weekends',
            value: formData.weekendscheckboxyes ? "Yes" : "No",
        });

        setFormData({
            name: 'holidays',
            value: formData.holidayscheckboxyes ? "Yes" : "No",
        });
    }

    const handleFiles = event => {
        const resumeFiles = document.getElementById("employment-file-upload").files;
        // console.log(resumeFiles)
        setListofFiles([]);
        for (const file of resumeFiles) {
            setListofFiles(oldList => [...oldList, file]);
        }
    }

    /* 
    const handeUpdateSchedule = newSchedule => {
        setSchedule(newSchedule);
    }

    const handleShowSchedule = event => {
        setShowSchedule(!showSchedule);
    }
    */

    const handleRemoveFile = (id) => (event) => {
        setListofFiles(oldList => oldList.filter((item, index) => index !== id));
    }

    return <div className="employment">
        <br/>
        <Banner text="Database is temporarily offline, please come back later"/>
        <div className="employment-header">
            {props.language && "Want to Work with Us?"}
            {!props.language && "¿Quieres Trabajar con Nosotros?"}
        </div>
        <div className="employment-box">
            <form id="employment-form" onSubmit={handleSubmit}>
                <div className="employment-box-text">
                    {props.language && "Job"}
                    {!props.language && "Trabajo"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-box-job-select">
                    <Select
                        options={jobOptions}
                        onChange={(values) => setSelectedJob(values[0].value)}
                        className="employment-box-job-dropdown"
                        placeholder=""
                        searchable={false}
                    />
                </div>
                <div className="employment-box-text">
                    {props.language && "Name"}
                    {!props.language && "Nombre"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        name="firstname"
                        className="employment-edit-box employment-edit-box-width-small"
                        onChange={handleChange}
                        required
                    />
                    <span className="employment-break"/>
                    <input
                        name="middlename"
                        className="employment-edit-box employment-edit-box-width-small"
                        onChange={handleChange}
                    />
                    <span className="employment-break"/>
                    <input 
                        type="text" 
                        name="lastname"
                        className="employment-edit-box employment-edit-box-width-large"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    {props.language && "Street Address"}
                    {!props.language && "La Dirección"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text"
                        name="streetaddress"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    {props.language && "City/State/Zip"}
                    {!props.language && "Ciudad/Estado Código/Postal"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text"
                        name="citystatezip"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    {props.language && "Phone"}
                    {!props.language && "Teléfono"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="phone"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If hired, do you have a reliable means to get to work?"}
                        {!props.language && "Si es contratado, ¿tiene un medio confiable para llegar al trabajo?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="reliablework"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Please describe"}
                        {!props.language && "Por favor describa"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="reliableworkdescription"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you legally eligible for employment in the U.S.? (Proof of U.S. citizenship or immigration status is required if hired.)"}
                        {!props.language && "¿Es usted legalmente elegible para trabajar en los EE. UU.? (Se requiere prueba de ciudadanía estadounidense o estatus migratorio si se contrata)."}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="legalemployment"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Have you been convicted of a crime? (Massachusetts applicants should not include misdemeanor convictions; California applicants should not include marijana-related convictions that occured more than 2 years prior to the application date.)"}
                        {!props.language && "¿Ha sido condenado por un crimen? (Los solicitantes de Massachusetts no deben incluir condenas por delitos menores; los solicitantes de California no deben incluir condenas relacionadas con la marijana que ocurrieron más de 2 años antes de la fecha de solicitud)."}
                        <span className="employment-box-red-text">* </span>
                        &nbsp;&nbsp;{props.language && "Yes"}{!props.language && "Sí"}&nbsp;
                        <input
                            type="checkbox"
                            name="crimecheckboxyes"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;No&nbsp;
                        <input
                            type="checkbox"
                            name="crimecheckboxno"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <br/>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If yes, state the nature of the offense and disposition of the case. Include dates and places. (NOTE: The existence of a criminal record does not constitute an automatic bar to employment.) If you clicked no above, put \"N/A\"."}
                        {!props.language && "En caso afirmativo, indique la naturaleza del delito y la disposición del caso. Incluye fechas y lugares. (NOTA: La existencia de antecedentes penales no constituye una prohibición automática de empleo). Si hizo clic en No arriba, escriba \"N/A\"."}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <textarea 
                        type="text" 
                        name="crimedescription"
                        className="employment-edit-box employment-edit-box-text-area"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you a veteran?"}
                        {!props.language && "Ustedes un veterano?"}
                        <span className="employment-box-red-text">*</span>
                        &nbsp;&nbsp;{props.language && "Yes"}{!props.language && "Sí"}&nbsp;
                        <input 
                            type="checkbox" 
                            name="veterancheckboxyes"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;No&nbsp;
                        <input 
                            type="checkbox" 
                            name="veterancheckboxno"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If yes, give dates of service:"}
                        {!props.language && "En caso afirmativo, indique las fechas de servicio:"}
                        &nbsp;&nbsp;&nbsp;From:&nbsp;&nbsp;
                        <input
                            type="date"
                            className="employment-date-input"
                            name="veteranstart"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;&nbsp;To:&nbsp;&nbsp;
                        <input
                            type="date"
                            className="employment-date-input"
                            name="veteranend"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Please list any special skills or training:"}
                        {!props.language && "Por favor indique cualquier habilidad o capacitación especial:"}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="veterantraining"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you seeking full time, part time or temporary employment?"}
                        {!props.language && "¿Está buscando empleo a tiempo completo, a tiempo parcial o temporal?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="employmenttype"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "What hours and shift(s) would you prefer to work?"}
                        {!props.language && "¿Qué horas y turnos preferiría trabajar?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="employmenthours"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "List times you are not available to work:"}
                        {!props.language && "Enumere las horas en las que no está disponible para trabajar:"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="employmenthoursno"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you willing to work overtime?"}
                        {!props.language && ""}
                        <span className="employment-box-red-text">*</span>
                        &nbsp;&nbsp;{props.language && "Yes"}{!props.language && "Sí"}&nbsp;
                        <input
                            type="checkbox"
                            name="overtimecheckboxyes"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;No&nbsp;
                        <input
                            type="checkbox"
                            name="overtimecheckboxno"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Weekends?"}
                        {!props.language && "¿Fines de semana?"}
                        <span className="employment-box-red-text">*</span>
                        &nbsp;&nbsp;{props.language && "Yes"}{!props.language && "Sí"}&nbsp;
                        <input
                            type="checkbox"
                            name="weekendscheckboxyes"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;No&nbsp;
                        <input
                            type="checkbox"
                            name="weekendscheckboxno"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Holidays?"}
                        {!props.language && "Las Fiestas?"}
                        <span className="employment-box-red-text">*</span>
                        &nbsp;&nbsp;{props.language && "Yes"}{!props.language && "Sí"}&nbsp;
                        <input
                            type="checkbox"
                            name="holidayscheckboxyes"
                            onChange={handleChange}
                        />
                        &nbsp;&nbsp;No&nbsp;
                        <input
                            type="checkbox"
                            name="holidayscheckboxno"
                            onChange={handleChange}
                        />
                    </span>
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you currently employed?"}
                        {!props.language && "¿Está trabajando actualmente?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="currentlyemployed"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If hired, when would you be able to start?"}
                        {!props.language && "Si es contratado, ¿cuándo sería capaz de comenzar?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="hiredstart"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Have you ever been discharged or asked to resign from any position?"}
                        {!props.language && "¿Alguna vez lo han despedido o le han pedido que renuncie a algún puesto?"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="priordischarge"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If yes, please describe:"}
                        {!props.language && "En caso afirmativo, describa:"}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="priordischargedescription"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Please list your last employer:"}
                        {!props.language && "Por favor indique su último empleador:"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="lastemployer"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Last employer's phone number:"}
                        {!props.language && "Número de teléfono del último empleador:"}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="lastemployerphone"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                {/*
                <div className="employment-box-text">
                    <span>
                        {props.language && "Have you ever worked for this organization before?"}
                        {!props.language && ""}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="workedherebefore"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "If yes, name used:"}
                        {!props.language && ""}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="workedherebeforename"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "List any friends or relatives employed by this company:"}
                        {!props.language && ""}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="employmentfriends"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Are you able to perform all tasks required for this position with or without reasonable accommodation?"}
                        {!props.language && ""}
                        <span className="employment-box-red-text">*</span>
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="abletoperform"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Please describe which tasks, if any, you will need accommodation to perform, and explain what type of accommodation you will need:"}
                        {!props.language && ""}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="accommodationdetail"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                <div className="employment-box-text">
                    <span>
                        {props.language && "Please describe:"}
                        {!props.language && ""}
                    </span>
                </div>
                <div className="employment-edit-field">
                    <input 
                        type="text" 
                        name="accommodationdescription"
                        className="employment-edit-box employment-edit-box-width-max"
                        onChange={handleChange}
                    />
                </div>
                */}
                {/*
                <div className="employment-box-text">
                    {props.language && "Availability"}
                    {!props.language && "Disponibilidad"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <button 
                    className="employment-schedule-dropdown" 
                    type="button" 
                    onClick={handleShowSchedule}
                >
                    {showSchedule && <>
                        {props.language && "Hide Schedule"}
                        {!props.language && "Ocultar Horario"}
                    </>}
                    {!showSchedule && <>
                        {props.language && "Show Schedule"}
                        {!props.language && "Mostrar Horario"}
                    </>}
                </button>
                {showSchedule && <div className="employment-schedule-container">
                    <div className="employment-schedule">
                        <ScheduleSelector
                            selection={schedule}
                            numDays={7}
                            minTime={8}
                            maxTime={22}
                            hourlyChunks={1}
                            onChange={handeUpdateSchedule}
                            selectionScheme="linear"
                            dateFormat="ddd"
                            startDate={"Mon Jun 12 2023 00:00:00 GMT+0000"}
                            timeFormat="ha"
                            className="employment-schedule"
                        />
                    </div>
                </div>}
                */}
                <div className="employment-box-text">
                    {props.language && "Resume"}
                    {!props.language && "Currículum"}
                    <span className="employment-box-red-text">*</span>
                </div>
                <div className="employment-edit-field">
                    <label htmlFor="employment-file-upload" className="employment-file-upload">
                        {props.language && "Upload PDF(s)"}
                        {!props.language && "Cargar PDF(s)"}
                    </label>
                    <input
                        id="employment-file-upload"
                        type="file"
                        multiple
                        name="resume"
                        onChange={handleFiles}
                        accept="application/pdf"
                    />
                </div>
                <div id="employment-filelist" className="employment-file-list">
                    {listOfFiles.map((file, id) => {
                        return (
                            <div 
                                key={id}
                            >
                                <HideContentButton 
                                    buttonClassName="employment-pdf-button"
                                    hiddenText={`Preview ${file.name}`}
                                    visibleText={`Hide ${file.name}`}
                                >
                                    <div className="employment-pdf">
                                        <DisplayPDF pdf={file} width={windowWidth * 0.65}/>
                                    </div>
                                </HideContentButton>
                                <button 
                                    type="button"
                                    onClick={handleRemoveFile(id)}
                                    className="employment-pdf-remove-button"
                                >
                                    <img src={whiteIcon} alt="remove" className="employment-pdf-remove-icon" width={30}/>
                                </button>
                            </div>
                        );
                    })}
                </div>
                <button 
                    type="submit"
                    className="employment-submit-button"
                    disabled={submitting}
                >
                    {props.language && "Submit"}
                    {!props.language && "Enviar"}
                </button>
            </form>
            {generatedApplicationPdf && <div className="employment-confirmation-pdf">
                <DisplayPDF pdf={generatedApplicationPdf} width={windowWidth * 0.65}/>
                <Link to={generatedApplicationPdf} target="_blank" download>
                    {props.language && "Download"}
                    {!props.language && "Descargar"}
                </Link>
            </div>}
        </div>
        <br/>
        <br/>
    </div>
}

export default Employment;