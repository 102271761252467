const Margaritas = [
	{
		filename: "blue.jpg"
	},
	{
		filename: "green.jpg"
	},
	{
		filename: "green2.jpg"
	},
	{
		filename: "orange.jpg"
	},
	{
		filename: "red.jpg"
	},
	{
		filename: "yellow.jpg"
	},
];
export { Margaritas }
