import React, { useReducer, useState, useEffect } from "react";
import Select from "react-dropdown-select";
import "../styles/CommentViewer.scss";

function CommentViewer(props) {
    const commentOptions = [
        {
            value: 1,
            label: "Show Unread"
        },
        {
            value: 2,
            label: "Show Read"
        },
        {
            value: 3,
            label: "Show All"
        }
    ];

    const [dropdownSelected, setDropdownSelected] = useState(1);

    const [commentOptionSelectedTotal, setCommentOptionSelectedTotal] = useState(0);

    const [loginView, setLoginView] = useState(true);

    const [submitting, setSubmiting] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [commentData, setCommentData] = useState({});

    const [loading, setLoading] = useState(false); 

    const [newestFirst, setNewestFirst] = useState(false);

    const formReducer = (state, event) => {
        if(event.reset) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
                commentStart: state.commentStart ? state.commentStart : 1,
                commentEnd: state.commentEnd ? state.commentEnd : 10,
                newFirst: state.newFirst ? state.newFirst : false,
                type: state.type ? state.type : "unread",
            }
        }

        if (event.newestFirst) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
                commentStart: commentOptionSelectedTotal - 9,
                commentEnd: commentOptionSelectedTotal,
                newFirst: true,
                type: state.type
            }
        }   
        if (event.oldestFirst) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
                commentStart: 1,
                commentEnd: 10,
                newFirst: false,
                type: state.type
            }
        }
        if (event.prev) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
                commentStart: newestFirst ? +state.commentStart + 10 : +state.commentStart - 10,
                commentEnd: newestFirst ? +state.commentEnd + 10 : +state.commentEnd - 10,
                newFirst: state.newFirst,
                type: state.type
            }
        }
        if (event.next) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
                commentStart: newestFirst ? +state.commentStart - 10 : +state.commentStart + 10,
                commentEnd: newestFirst ? +state.commentEnd - 10 : +state.commentEnd + 10,
                newFirst: state.newFirst,
                type: state.type
            }
        }
        if (event.setUnread) {
            return {
                username: state.username,
                password: state.password,
                commentStart: state.newFirst ? commentOptionSelectedTotal - 9 : 1,
                commentEnd: state.newFirst ? commentOptionSelectedTotal : 10,
                newFirst: state.newFirst,
                type: "unread",
            }
        }
        if (event.setRead) {
            return {
                username: state.username,
                password: state.password,
                commentStart: state.newFirst ? commentOptionSelectedTotal - 9 : 1,
                commentEnd: state.newFirst ? commentOptionSelectedTotal : 10,
                newFirst: state.newFirst,
                type: "read",
            }
        }
        if (event.setAll) {
            return {
                username: state.username,
                password: state.password,
                commentStart: state.newFirst ? commentOptionSelectedTotal - 9 : 1,
                commentEnd: state.newFirst ? commentOptionSelectedTotal : 10,
                newFirst: state.newFirst,
                type: "all",
            }
        }

        return {
            ...state,
            [event.name]: event.value
        }
    }

    const [pageNumber, setPageNumber] = useReducer(formReducer, {});

    const getComments = async () => {
        // console.log("getComments")

        // get total comment data
        try {
            const response = await fetch('/api/total-comments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pageNumber)
            });
            if (response.status !== 200) {
                throw new Error(`Request failed: ${response.status}`); 
            }
            const body = await response.json();
            setCommentOptionSelectedTotal(dropdownSelected === 1 ? body.totalUnread :
                                          dropdownSelected === 2 ? body.totalRead :
                                          body.totalComments);
        } catch (err) {
            alert(`Comment total retrieval failed! ${err.message}`);
        }

        const response = await fetch('/api/retrieve-comments', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pageNumber)
        });
        if (response.status !== 200) {
            throw new Error(`Request failed: ${response.status}`); 
        }
        const body = await response.json();
        return body;
    }

    const handleSubmit = async (event) => {
        // console.log("handleSubmit")
        event.preventDefault();
        setSubmiting(true);
        await new Promise(resolve => setTimeout(resolve, 3000)); // wait 3 seconds
        try {
            const newCommentData = await getComments();
            setPageNumber({
                reset: true
            })
            if (Object.keys(newCommentData).length === 0) {
                alert("Invalid login");
            } else {
                setCommentData(newCommentData);
                setLoginView(false);
            }
        } catch (err) {
            alert(`Comment retrieval failed! ${err.message}`);
        }
        setSubmiting(false);
    }

    useEffect(() => {
        // console.log("setLoading(false) useEffect")
        setLoading(false);
    }, [commentData]);

    const handleDataInput = async (event) => {
        // console.log("handleDataInput")
        try {
            const newCommentData = await getComments();
            setCommentData(newCommentData);
        } catch (err) {
            alert(`Comment retrieval failed! ${err.message}`);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(loading) {
            // console.log("loading active call handleDataInput useEffect")
            handleDataInput();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        // console.log("setPageNumber reset useEffect")
        setPageNumber({
            reset: true
        });
    }, []);

    useEffect(() => {
        // console.log("loginView off call setPageNumber for dropdownSelected useEffect")
        if (!loginView) {
            setLoading(true);
            switch(dropdownSelected) {
                case 1: // unread
                    setPageNumber({
                        setUnread: true
                    });
                break;
                case 2: // read
                    setPageNumber({
                        setRead: true
                    });
                break;
                case 3: // all
                    setPageNumber({
                        setAll: true
                    });
                break;
                default: // unread
                    setPageNumber({
                        setUnread: true
                    });
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownSelected]);

    const handleChange = event => {
        // console.log("handleChange event")
        setPageNumber({
            name: event.target.name,
            value: event.target.value,
        });
    } 

    const handlePrev = async (event) => {
        // console.log("handlePrev event")
        setLoading(true);
        setPageNumber({
            prev: true
        });
    }

    const handleNext = async (event) => {
        // console.log("handleNext event")
        setLoading(true);
        setPageNumber({
            next: true
        });
    }

    const flipVisibility = event => {
        // console.log("flipVisibility event")
        setShowPassword(!showPassword);
    }

    const flipNewestFirst = event => {
        // console.log("flipNewestFirst event")
        setLoading(true);
        if (newestFirst) {
            // set to oldest first
            setPageNumber({
                oldestFirst: true
            });
        } else {
            // set to newest first
            setPageNumber({
                newestFirst: true
            });
        }
        setNewestFirst(!newestFirst);
    }

    const handleCommentRead = (id) => async (event) => {
        // console.log("handleCommentRead event")
        try {
            const response = await fetch('/api/comment-read', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: pageNumber.username,
                    password: pageNumber.password,
                    id: id,
                })
            });
            if (response.status !== 200) {
                throw new Error(`Request failed: ${response.status}`); 
            }
        } catch (err) {
            alert(`Comment update failed! ${err.message}`);
        }
        handleDataInput();
    }

    const pageButtons = <span>
        <button 
            className="comment-viewer-button" 
            disabled={newestFirst ? pageNumber.commentEnd >= commentOptionSelectedTotal : pageNumber.commentStart < 2}
            onClick={handlePrev}
        >
            previous page
        </button>
        <button
            className="comment-viewer-button"
            onClick={flipNewestFirst}
        >
            {newestFirst && <>showing newest first</>}
            {!newestFirst && <>showing oldest first</>}
        </button>
        <button 
            className="comment-viewer-button"
            disabled={newestFirst ? pageNumber.commentStart < 2 : pageNumber.commentEnd >= commentOptionSelectedTotal}
            onClick={handleNext}
        >
            next page
        </button>
    </span>

    return <>
        <br/>
        {loginView && <div className="comment-viewer">
            <form id="comment-viewer-form" onSubmit={handleSubmit}>
                {"username\t"}
                <input type="text" 
                    name="username"
                    onChange={handleChange}
                    required
                />
                <br/>
                {"password\t"}
                <input
                    type={showPassword ? "text" : "password"} 
                    name="password"
                    onChange={handleChange}
                    required
                />
                <button type="button" onClick={flipVisibility}>
                    {showPassword && <>
                        hide password
                    </>}
                    {!showPassword && <>
                        show password
                    </>}
                </button>
                <br/>
                <button 
                        type="submit"
                        className="contact-us-submit-button"
                        disabled={submitting}
                    >
                        Submit
                </button>
                {submitting && <div>
                    Submitting...    
                </div>}
            </form>
        </div>}
        {(!loginView && !loading) && <div className="comment-viewer">
            <Select
                options={commentOptions}
                onChange={(values) => setDropdownSelected(values[0].value)}
                searchable={false}
                className="comment-viewer-dropdown-select"
                values={[commentOptions[dropdownSelected ? dropdownSelected - 1 : 0]]}
            />
            {pageButtons}
            <br/>
            {Object.values(commentData).map((items, i) => <div key={i} className={items.hasRead ? "comment-viewer-item comment-viewer-item-read" : "comment-viewer-item"}>
                    {!props.showMobile && <span className="comment-viewer-split-screen">
                        <span>
                            {"Topic:\t" + items.topic + "\t"}
                        </span>
                        <span>
                            {"" + new Date(+items.timestamp)}
                        </span>
                    </span>}
                    {props.showMobile && <>
                        <span>{"Topic:\t" + items.topic + "\t"}</span>
                        <span>{"Date:\t" + new Date(+items.timestamp)}</span>
                        <br/>
                    </>}
                    <span>{"Name:\t" + items.firstname + "\t" + items.lastname}</span>
                    <span>{"Email:\t" + items.email}</span>
                    <span>{"Phone Number:\t" + items.phone}</span>
                    <span>{"Comment:\t" + items.comments}</span>
                    <button 
                        onClick={handleCommentRead(items.id)}
                        className="comment-viewer-read-button"
                    >
                        {!items.hasRead && "Mark as read"}
                        {items.hasRead && "Mark as unread"}
                    </button>
            </div>)}
            {Object.values(commentData).length === 0 && <div className="comment-viewer-item comment-viewer-item-read comment-viewer-no-messages">
                No Messages found
            </div>}
            {pageButtons}
            <br/>
            <br/>
        </div>}
        {loading && <div className="comment-viewer-loading">
                Loading...
        </div>}
    </>
}

export default CommentViewer;
