import React, { useReducer, useState } from "react";
import { Banner } from "./Banner";
import '../styles/ContactUs.scss';

const formReducer = (state, event) => {
    if(event.reset) {
        return {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            topic: '',
            comments: '',
        }
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const validatePhone = (phone) => {
    return phone.match(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
    );
}

function ContactUs(props) {
    const [formData, setFormData] = useReducer(formReducer, {});

    const [submitting, setSubmitting] = useState(false);

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const saveFormData = async () => {
        const response = await fetch('/api/comment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
        if (response.status !== 200) {
            throw new Error(`Request failed: ${response.status}`); 
        }
        // const body = await response.text();
        // console.log(body);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateEmail(formData.email)) {
            alert("Valid email required");
            return;
        }

        if (!validatePhone(formData.phone)) {
            alert("Valid phone number required");
            return;
        }

        if(!formData.firstname || 
                !formData.lastname || 
                !formData.email || 
                !formData.phone || 
                !formData.topic ||
                !formData.comments
            ) {
                alert("One or more of the fields are empty");
                return;
        }

        setSubmitting(true);
        try {
            await saveFormData();
            // alert('Your comment was successfully submitted!');
            setFormData({
                reset: true
            });
            // Take user to thank you for comment page
            setHasSubmitted(true);
        } catch (err) {
            alert(`Comment submission failed! ${err.message}`);
        }
    }

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    return <>
    <br/>
    <Banner text="Database is temporarily offline, please come back later"/>
    {!hasSubmitted && <div className="contact-us">
            <div className="contact-us-header">
                {props.language && "Have Comments?"}
                {!props.language && "¿Tiene Comentarios?"}
            </div>
            <div className="contact-us-text">
                {props.language && "We love hearing back from our guests! If you would like to leave us any feedback, please use the form below."}
                {!props.language && "Nos encanta escuchar de nuestros huéspedes! Si desea dejarnos algún comentario, por favor utilice el siguiente formulario."}
            </div>
            <div className="contact-us-box">
                <form id="contact-us-form" onSubmit={handleSubmit}>
                    <div className="contact-us-box-text">
                        {props.language && "Name"}
                        {!props.language && "Nombre"}
                        <span className="contact-us-box-red-text">*</span>
                    </div>
                    <div className="contact-us-edit-field">
                        <input 
                            name="firstname"
                            className="contact-us-edit-box contact-us-edit-box-width-small"
                            onChange={handleChange}
                            required
                        />
                        <span className="contact-us-break"/>
                        <input 
                            type="text" 
                            name="lastname"
                            className="contact-us-edit-box contact-us-edit-box-width-large"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-us-box-text">
                        Email<span className="contact-us-box-red-text">*</span>
                    </div>
                    <div className="contact-us-edit-field">
                        <input 
                            type="text"
                            name="email"
                            className="contact-us-edit-box contact-us-edit-box-width-max"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-us-box-text">
                        {props.language && "Phone"}
                        {!props.language && "Teléfono"}
                        <span className="contact-us-box-red-text">*</span>
                    </div>
                    <div className="contact-us-edit-field">
                        <input 
                            type="text" 
                            name="phone"
                            className="contact-us-edit-box contact-us-edit-box-width-max"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-us-box-text">
                        {props.language && "Topic"}
                        {!props.language && "Tema"}
                        <span className="contact-us-box-red-text">*</span>
                    </div>
                    <div className="contact-us-edit-field">
                        <input 
                            type="text" 
                            name="topic"
                            className="contact-us-edit-box contact-us-edit-box-width-max"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="contact-us-box-text">
                        {props.language && "Comments"}
                        {!props.language && "Comentarios"}
                        <span className="contact-us-box-red-text">*</span>
                    </div>
                    <div className="contact-us-edit-field">
                        <textarea
                            name="comments"
                            className="contact-us-edit-box contact-us-edit-box-width-max contact-us-edit-box-height-long"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button 
                        type="submit"
                        className="contact-us-submit-button"
                        disabled={submitting}
                    >
                        {props.language && "Submit"}
                        {!props.language && "Enviar"}
                    </button>
                    {submitting && <div className="contact-us-box-text">
                        {props.language && "Submitting Comment..."}
                        {!props.language && "Enviando Comentario..."}
                    </div>}
                </form>
            </div>
            <br/><br/>
        </div>}
        {hasSubmitted && <div className="contact-us">
            <div className="contact-us-header">
                {props.language && "Thanks for commenting!"}
                {!props.language && "¡Gracias por comentar!"}
            </div>
            <img alt="submission thanks" src={process.env.PUBLIC_URL + "/ContactUsThanks.png"} width={900} />
            <br/>
            <br/>
            <div className="contact-us-text">
                {props.language && "Your comment has been submitted and recieved. We thank you for your feedback and hope to see you again soon!"}
                {!props.language && "Su comentario ha sido enviado y recibido. ¡Le agradecemos sus comentarios y esperamos volver a verle pronto!"}
            </div>
        </div>}
    </>
}

export { validateEmail, validatePhone };
export default ContactUs;
