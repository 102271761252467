import React from "react";
import "../styles/Catering.scss";
import DisplayPDF from "./DisplayPDF";
import { Link } from "react-router-dom";

const PartyTrayUrl = process.env.PUBLIC_URL + "/PartyTrayMenu.pdf";
const BuffetFormUrl = process.env.PUBLIC_URL + "/BuffetOrderForm.pdf";

function MobileCatering(props) {
    return <>
        <header className="catering-header">
            Catering
        </header>
        <div className="catering">
            <div className="vertical-list gray-dark-background">
                <br/>
                <div className="catering-pdf">
                    <Link to="/PartyTrayMenu.pdf" target="_blank" className="catering-pdf-link">
                        {props.language && 
                        <>
                            View Party Tray PDF
                        </>}
                        {!props.language &&
                        <>
                            Ver Bandeja de Fiesta PDF
                        </>}
                    </Link>
                    <br/><br/>
                    <DisplayPDF pdf={PartyTrayUrl} width={window.innerWidth * 0.9}/>
                </div>
                <br/>
                <div className="catering-pdf">
                    <Link to="/BuffetOrderForm.pdf" target="_blank" className="catering-pdf-link">
                        {props.language &&
                        <>
                            View Buffet Order Form PDF
                        </>}
                        {!props.language &&
                        <>
                            Ver Formulario de pedido buffet PDF
                        </>}
                    </Link>
                    <br/><br/>
                    <DisplayPDF pdf={BuffetFormUrl} width={window.innerWidth * 0.9}/>
                </div>
            </div>
        </div>
    </>
}

export default MobileCatering;