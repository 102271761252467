import React, { useState } from 'react';
import '../styles/HamburgerButton.scss';
import { Link } from 'react-router-dom';

function HamburgerButton (props) {
    const [openMenu, setOpenMenu] = useState(false);
    const setMenu = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <>
            <button className="hamburger" onClick={setMenu}>
                <img src={process.env.PUBLIC_URL + "/hamburger-button.png"} alt="hamburger-button" width={48} height={48}/>
            </button>
            {openMenu && <div className="menu" onClick={setMenu}>
                <li className="item">
                    <Link to="/" className="link">
                        {props.language && "Home"}
                        {!props.language && "Hogar"}
                    </Link>
                </li>
                <li className="item">
                    <Link to="/catering" className="link">
                        Catering
                    </Link>
                </li>
                <li className="item">
                    <Link to="/contact-us" className="link">
                        {props.language && "Contact Us"}
                        {!props.language && "Contáctenos"}
                    </Link>
                </li>
                <li className="item">
                    <Link to="/about-us" className="link">
                        {props.language && "About Us"}
                        {!props.language && "Sobre Nosotros"}
                    </Link>
                </li>
                <li className="item">
                    <Link to="employment" className="link">
                        {props.language && "Employment"}
                        {!props.language && "Empleo"}
                    </Link>
                </li>
            </div>}
        </>
    );
}

export { HamburgerButton }