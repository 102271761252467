import React, { useReducer, useState } from "react";
import '../styles/ApplicationViewer.scss';
import axios from "axios";

function ApplicationViewer() {
    const formReducer = (state, event) => {
        if(event.reset) {
            return {
                username: state.username ? state.username : "",
                password: state.password ? state.password : "",
            }
        }

        return {
            ...state,
            [event.name]: event.value
        }
    }

    const [formData, setFormData] = useReducer(formReducer, {});

    const [showPassword, setShowPassword] = useState(false);

    const [submitting, setSubmiting] = useState(false);

    const [viewApplications, setViewApplications] = useState(false);

    const flipVisibility = event => {
        // console.log("flipVisibility event")
        setShowPassword(!showPassword);
    }

    const handleChange = event => {
        // console.log("handleChange event")
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const getApplications = async() => {
        try {
            const response = await fetch('/api/employee-application-view', {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            console.log("response")
            console.log(response)
            console.log(response.data)
            if (response.status !== 200) {
                throw new Error(`Request failed: ${response.status}`); 
            }
            console.log("here in between")
            return response.data;
        } catch (err) {
            alert(`Login or application retrieval failed! ${err.message}`);
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmiting(true);
        await new Promise(resolve => setTimeout(resolve, 3000)); // wait 3 seconds
        try {
            console.log("here 1")
            const response = await getApplications();
            console.log("here 2")
            console.log(response)
        }
        catch (err) {
            alert(`Application retrieval failed! ${err.message}`);
        }
        setSubmiting(false);
    }

    return <>
        <br/>
        {!viewApplications && <div className="application-viewer">
            <form id="application-viewer-form" onSubmit={handleSubmit}>
                {"username\t"}
                <input type="text" 
                    name="username"
                    onChange={handleChange}
                    required
                />
                <br/>
                {"password\t"}
                <input
                    type={showPassword ? "text" : "password"} 
                    name="password"
                    onChange={handleChange}
                    required
                />
                <button type="button" onClick={flipVisibility}>
                    {showPassword && <>
                        hide password
                    </>}
                    {!showPassword && <>
                        show password
                    </>}
                </button>
                <br/>
                <button 
                        type="submit"
                        className="application-submit-button"
                        disabled={submitting}
                    >
                        Submit
                </button>
                {submitting && <div>
                    Submitting...    
                </div>}
            </form>
        </div>}
        {viewApplications && <div>

        </div>}
    </>
}

export default ApplicationViewer;