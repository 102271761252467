import React from "react";
import ImageCarousel from "./ImageCarousel";
import '../styles/Home.scss';
import '../styles/Specials.scss';
import { Margaritas } from "./MargaritaFiles";

function Home(props) {
    const mapMargaritaImages = Margaritas.map((file) => ({
        src: process.env.PUBLIC_URL + "/Margaritas/" + file.filename
    }));

    return <>
        <br/>
        {!props.showMobile && <div className="home"> {/* Desktop View */}
            <div className="main">
                <div className="column">
                    {/* Yellow Post */}
                    <div className="post border-yellow">
                        <header className="header header-yellow">
                            {props.language && "Hours"}
                            {!props.language && "Horas"}
                        </header>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={243} height={20} className="break"/>
                        <div className="text">
                            {props.language && "Mon-Sat "}
                            {!props.language && "Lun-Sáb "}
                            11am-9pm<br/>
                            {props.language && "Sun "}
                            {!props.language && "Dom "}
                            12pm-9pm
                        </div>
                    </div>
                    <br/>
                    {/* Green Post */}
                    <div className="post border-green">
                        <header className="header header-green">
                            Margaritas
                        </header>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={243} height={20} className="break"/>
                        <div className="text">
                            <ImageCarousel mapImages={mapMargaritaImages} height={365} width={250}/>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <img src={process.env.PUBLIC_URL + "/TCLogo.jpg"} alt="logo" />
                    <div className="est-title">Est. 1966</div>
                </div>
                <div className="column">
                    {/* Blue Post */}
                    <div className="post border-blue">
                        <header className="header header-blue">
                            {props.language && "Location"}
                            {!props.language && "Local"}
                        </header>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={243} height={20} className="break"/>
                        <div className="text">
                            <a href="https://goo.gl/maps/tXfZr7X45K3xJxvZ9" className="link">
                                1715 N. Telegraph,<br/>
                                Waterford, MI<br/>
                                (248) 332-6851
                            </a>
                        </div>
                    </div>
                    <br/>

                    {/* Red Post */}
                    <div className="post border-red">
                        <header className="header header-red">
                            {props.language && "World Class Nachos"}
                            {!props.language && "Lleva El Mejor Nacho" /* Need smaller text, maybe better translation? */}
                        </header>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={243} height={20} className="break"/>
                        <div className="Text">
                            <img src={process.env.PUBLIC_URL + "/Nachos.jpg"} alt="nachos" width={240} className="nachos"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="end"/>
        </div>}
        {props.showMobile && <div className="home mobile"> {/* Mobile View */}
            <div className="logo">
                <img src={process.env.PUBLIC_URL + "/TCLogo.jpg"} alt="logo" width={window.innerWidth > 300 ? 300 : window.innerWidth}/>
                <div className="est-title">Est. 1966</div>
            </div>
            <br/>
            <div className="block">
                {/* Hours and Location */}
                <div className="post border-red post-wide post-mobile">
                    <header className="header header-red header-wide header-mobile">
                        {props.language && "Hours & Location"}
                        {!props.language && "Horas y Local"}
                    </header>
                    <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={window.innerWidth > 360 ? 350 : window.innerWidth * .85} className="break"/>
                    <div className="text">
                        {props.language && "Mon-Sat "}
                        {!props.language && "Lun-Sáb "}
                       11am-9pm<br/>
                       {props.language && "Sun "}
                       {!props.language && "Dom "}
                       12pm-9pm<br/>
                       1750 N. Telegraph, Waterford, MI
                    </div>
                </div>
            </div>
            <div className="specials">
                <div className="vertical-list">
                    <div className="horizontal-block"/>
                    <div className="post border-blue post-mobile">
                        <div className="header header-blue">
                            {props.language && "Daily Specials"}
                            {!props.language && "Platos del Día"}
                        </div>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={window.innerWidth > 360 ? 350 : window.innerWidth * .85} className="break"/>
                        <div className="text">
                            {props.language && 
                            <>
                                Monday – $2.00 off Nacho Supreme
                            </>}
                            {!props.language &&
                            <>
                                Lunes - $2.00 de descuento Nacho Supreme
                            </>}
                            <br/>
                        </div>
                        <br/>
                        <div className="text">
                            {props.language && 
                            <>
                                Tuesday – Taco Tuesday<br/>
                                6-Pack Hard shell:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$10.99<br/>
                                10-Pack Hard Shell:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$15.99<br/>
                                *Chicken &amp; soft shell add $1.00
                            </>}
                            {!props.language && 
                            <>
                                Martes - Taco Tuesday<br/>
                                Paquete de seis de cascara dura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$10.99<br/>
                                Paquete de diez de cascara dura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$15.99<br/>
                                *Pollo &amp; cascara suave agregar $1.00
                            </>}
                        </div>
                    </div>
                    <div className="horizontal-block"/>
                    <div className="post border-green post-mobile">
                        <div className="header header-green">
                            {props.language && "Monthly Drink Specials"}
                            {!props.language && "Bebidas Especiales Mensuales"}
                        </div>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={window.innerWidth > 360 ? 350 : window.innerWidth * .85} className="break"/>
                        <div className="text">
                            {props.language && 
                            <>
                                Margarita of the Month:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$6<br/>
                                Frozen or on the rocks<br/>
                                <br/>
                                House Sangria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$7<br/>
                                Blend of white or red wine, brandy, hard seltzer, citrus juices, topped with cinnamon<br/>
                                <br/>
                                Mexican Beer:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3.50<br/>
                                Pacifico
                                Estrella Jalisco
                                Modelo Especial
                            </>}
                            {!props.language && 
                            <>
                                Margarita del Mes:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$6<br/>
                                Congelado o en las rocas:<br/>
                                <br/>
                                House Sangria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$7<br/>
                                Mezcla de vino blanco o tinto, brandy, seltzer duro, zumos de cítricos, cubierto con canela<br/>
                                <br/>
                                Cerveza Mexicana:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;$3.50<br/>
                                Pacifico
                                Estrella Jalisco
                                Modelo Especial
                            </>}
                        </div>
                    </div>
                    <div className="horizontal-block"/>
                    <div className="post border-red post-mobile">
                        <div className="header header-red">
                            {props.language && "Discounts"}
                            {!props.language && "Descuentos"}
                        </div>
                        <img src={process.env.PUBLIC_URL + "/post-break.png"} alt="break" width={window.innerWidth > 360 ? 350 : window.innerWidth * .85} height={25} className="break"/>
                        <div className="text">
                            {props.language && 
                            <>
                                Veteran and Military:<br/>10% off<br/>
                                <br/>
                                Oakland County employees:<br/>10% off
                            </>}
                            {!props.language && 
                            <>
                                Veteranos y militares:<br/>10% de descuento<br/>
                                <br/>
                                Empleados del condado de Oakland:<br/>10% de descuento
                            </>}
                        </div>
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>}
    </>
}

export default Home;