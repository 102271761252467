import React from "react";
import '../styles/NavBar.scss';
import { Link } from "react-router-dom";
import americanFlag from "../favicons/american-flag.ico";
import mexicanFlag from "../favicons/mexican-flag.ico";

const NavBar =(props)=> {
    const handleLanguageChange = event => {
        localStorage.setItem('triniandcarmensLanguage', !props.language)
        props.setLanguage(!props.language);
    }

    return <div className="nav-bar">
        <Link to="/" className="button">
            {props.language && "Home"}
            {!props.language && "Hogar" /* Not sure about this translation */}
        </Link>
        <Link to="/specials" className="button">
            {props.language && "Specials"}
            {!props.language && "Especiales"}
        </Link>
        <Link to="/menu" className="button">
            {props.language && "Menu"}
            {!props.language && "Menú"}
        </Link>
        <Link to="https://www.toasttab.com/trini-carmens-waterford/v3" className="button">
            {props.language && "Order Now"}
            {!props.language && "Pídalo Ya"}
        </Link>
        <Link to="/contact-us" className="button">
            {props.language && "Contact Us"}
            {!props.language && "Contáctenos"}
        </Link>
        <Link to="/about-us" className="button">
            {props.language && "About Us"}
            {!props.language && "Sobre Nosotros"}
        </Link>
        <Link to="/employment" className="button">
            {props.language && "Employment"}
            {!props.language && "Empleo"}
        </Link>
        <Link onClick={handleLanguageChange} className="button">
            {props.language && <img src={americanFlag} height={24} alt="English"></img>}
            {!props.language && <img src={mexicanFlag} height={24} alt="Español"></img>}
        </Link>
    </div>
}

export default NavBar