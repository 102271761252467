import React, { useState } from "react";

function HideContentButton(props) {
    const [showHidden, setShowHidden] = useState(props.showHidden ? true : false);

    const handleShowHidden = event => {
        setShowHidden(!showHidden);
    }

    return <>
        <button 
            type="button"
            onClick={handleShowHidden}
            className={props.buttonClassName}
        >
            {showHidden && <>{props.visibleText}</>}
            {!showHidden && <>{props.hiddenText}</>}
        </button>
        <>
            {showHidden && <>
                {props.children}
            </>}
        </>
        
    </>
}

export default HideContentButton;